import React from 'react';
import _ from 'lodash';
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import {toGermanFormat} from "../../../../../../../../utils/numberFormater";
import { getPortfolioCategory } from '../../../../../../../../components/CustomersDataProvider/utils'
import { GROUP_ACTION_DEPOT } from '../../../../../../../../components/Charts/InstrumentsAllocationTable/constants'
import {isTradingEnabled} from "../../../../../../../../components/TradingStore/utils";
import WarningTooltip from "../../../../../../../../components/WarningTooltip";

import {Link} from "react-router-dom";
import {
  REPORT_TYPES
} from "../../../../../../../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {buildCurrentCustomerVirtualPortfolioManager} from "../../../../../../../../routes";
import { isExpanded, wholeDepotOrderHandler } from '../../../../../../utils';
import {getCustodianData} from "../../../../../../../RiskProfiling/utils";
import {displayErrorSnackBar} from "../../../../../../../../components/SnackbarProvider/actions";
import UnrealizedProfitAndLossItem from "../UnrealizedProfitAndLossItem";
import Grid from "@material-ui/core/Grid";
import {getAuthSelector, getInvestmentPlatformSelector} from "../../../../../../../../utils/redaxSelectors";
import ChartSectionBordered, {
  ChartSectionPortfolioName
} from "../../../../../../components_v2/ChartSectionBordered/ChartSectionBordered";
import InstrumentsAllocationTable from "../../../../../../../../components/ChartsV2/InstrumentsAllocationTable";
import ClearingAccountTable from "../../../../../../../../components/ChartsV2/ClearingAccountTable";
import {getColorByValue, withEuroOrDash, withPercentOrDash} from "../../../../../../../../utils/utils";
import {getHostRelatedSetting} from "../../../../../../../../utils/sharedSettings";
import {NOT_FOUND} from "../../../../../../../../components/SharedSettingsProvider/constants";
import {createSelector} from "reselect";
import DashboardChartSection from '../../../DashboardChartSection';
import useStyles from './styles';


const getSharedSettings = (state) => state.get('sharedSettings');

const getSharedSettingsSelector = createSelector(
  [getSharedSettings],
  (sharedSettings) => sharedSettings.toJS()
)


const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  investmentPlatform: getInvestmentPlatformSelector(state),
  sharedSettings: getSharedSettingsSelector(state)
});

export const DepotSummaryNavigation = connect(mapStateToProps)((props) => {
  const {
    depot,
    sharedSettings,
    reportType,
    showColumnCallback,
  } = props;

  const classes = useStyles();

  const headerData = (() => {
    const columns = [{
      title: 'Wert',
      value: withEuroOrDash(depot.market_value),
      key: 'MarketValueCell',
      className: 'euroValue'
    }];

    if (reportType != REPORT_TYPES.DEPOT_STATUS.value) {
      columns.push({
        title: 'Anfangsbestand',
        value: withEuroOrDash(depot.profit_value_start),
        key: 'BeginningValueCell',
        className: 'euroValue'
      }, {
        title: 'Kapitaleinsatz',
        value: withEuroOrDash(depot.invested_amount),
        key: 'InvestedAmountCell',
        className: 'euroValue'
      }, {
        title: 'GuV (€)',
        value: (<>{depot.profit_loss_eur > 0 ? '+' : ''}{withEuroOrDash(depot.profit_loss_eur)}</>),
        color: getColorByValue(depot.profit_loss_eur),
        key: 'ReturnCellEuro'
      }, {
        title: 'GuV (%)',
        value: (<>{depot.profit_loss_perc > 0 ? '+' : ''}{withPercentOrDash(depot.profit_loss_perc, true)}</>),
        color: getColorByValue(depot.profit_loss_perc),
        key: 'ReturnCellPercentage'
      }, {
        title: 'GuV p.a. (%)',
        value: (<>{depot.profit_loss_percentage_pa > 0 ? '+' : ''}{withPercentOrDash(depot.profit_loss_percentage_pa, true)}</>),
        color: getColorByValue(depot.profit_loss_percentage_pa),
        warning: !!depot.portfolio_pa_warning && (getHostRelatedSetting(sharedSettings.data, depot.portfolio_pa_warning) || NOT_FOUND),
        key: 'ReturnPACell'
      });
    }

    return columns;

  })();

  return (
    <Grid container spacing={2} className={classes.headerValuesContainer}>
      {headerData.map((column) => {

        if (showColumnCallback && !showColumnCallback(column.key)) {
          return null;
        }

        return (
          <Grid item className={column.className}>
            <span className="title">{column.title}</span>
            <span className="value" style={column.color ? {color: column.color} : {}}>{column.value}</span>
            {!!column.warning && (
              <WarningTooltip title={column.warning}/>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
});

const InstrumentListItem = (props) => {
  const classes = useStyles();

  const {
    portfolio,
    virtualInstrumentLink,
    expandedItems,
    onExpanded,
    isCustomerApp,
    onAddTradingOption,
    onAddPortfolioTradingOption,
    tradings,
    banksMapping,
    tradeTransactions,
    reportType,
    auth,
    isVirtual,
    triggerPortfolioDeleting,
    refresh,
    customerData,
    canTradePortfolios,
    instrumentsGroupBy,

    withTransactionSaldo,
    withCompletelySoldAssets,
    unrealizedProfitAndLossData,
    footerText,
    showAssetsOverview,
  } = props;

  const isExpandedDepotSection = isExpanded(expandedItems, `depots.${portfolio.id}`);
  const isDataExist = !portfolio.is_historical || ((!portfolio.is_empty || !_.isEmpty(portfolio.components)) && portfolio.hasOwnProperty('value'));

  const dataSource = portfolio.components || [];

  let tradingEnabled = isTradingEnabled();
  const { isModelportfolio, isDisabled, bankCode, categoryName, disabledByMessage } = getPortfolioCategory(portfolio);
  const isBankAvailable = banksMapping && banksMapping.hasOwnProperty(bankCode);
  const isTradingAvailable = tradingEnabled && !portfolio.is_empty && !portfolio.is_passive && isBankAvailable;
  const isPtfTradingDisabled = (
    isDisabled ||
    (isModelportfolio && portfolio.is_empty) ||
    !!portfolio.is_passive || !isBankAvailable);

  const isPtfTradingEnabled = tradingEnabled && !isPtfTradingDisabled;

  const [custodiansInstrumentsData, setCustodiansInstrumentsData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [wholeDepotTradingAction, setWholeDepotTradingAction] = React.useState("default");

  React.useEffect(() => {
    if(isPtfTradingEnabled){
      setLoading(true);
      getCustodianData(dataSource, [bankCode], withCompletelySoldAssets).then(res => {
        if(res && !_.isEmpty(res[bankCode])){
          setCustodiansInstrumentsData(res[bankCode]);
        }
      }).catch(error => {
        props.dispatch(displayErrorSnackBar(error.detail || error.message || error))
      }).finally(() => setLoading(false));
    }
  }, [isPtfTradingEnabled]);

  const handleWholeDepotOrderChange = (event) => {
    const tradingType = event.target.value;
    if (tradings && onAddTradingOption && tradingType !== 'default') {
      (tradings.instruments || []).forEach(item => onAddTradingOption(portfolio, item.data, 'default'))
    }
    setWholeDepotTradingAction(tradingType);
    wholeDepotOrderHandler(portfolio, onAddTradingOption, tradingType, custodiansInstrumentsData);
  };

  const handleExpandedDepotsChange = (expanded) => {
    onExpanded(['instrumentsTab', 'depots', portfolio.id], expanded);
  };

  const onExpandedClearingAccountsChange = (expanded) => {
    onExpanded(['instrumentsTab', 'clearingAccounts', portfolio.id], expanded);
  };

  const onExpandedSecuritiesChange = (expanded) => {
    onExpanded(['instrumentsTab', 'securities', portfolio.id], expanded);
  };

  const onExpandedBaseFondsChange = (expanded) => {
    onExpanded(['instrumentsTab', 'baseFonds', portfolio.id], expanded);
  };

  const renderAssetTable = (data, subItemsField='instrumentsSubItems') => {
    const onExpandedSubItems = (elementId, expanded) => {
      onExpanded(['instrumentsTab', subItemsField, portfolio.id, elementId], expanded);
    };

    return <InstrumentsAllocationTable
      resource={props.resource}
      data={data || assetTableData}
      virtualInstrumentLink={virtualInstrumentLink}
      expanded={isExpandedDepotSection}
      expandedSubItems={_.get(expandedItems, [subItemsField, portfolio.id])}
      onExpandedSubItems={onExpandedSubItems}
      isCustomerApp={isCustomerApp}
      isVirtualOrderEnabled={props.isVirtualOrderEnabled}
      onAddTradingOption={handleAddTradingOption}
      handleAddPortfolioTradingOption={handleAddPortfolioTradingOption}
      handleWholeDepotOrderChange={handleWholeDepotOrderChange}
      wholeDepotTradingAction={wholeDepotTradingAction}
      tradings={tradings}
      tradeTransactions={tradeTransactions}
      reportType={reportType}
      isVirtual={isVirtual}
      refresh={refresh}
      isModelportfolio={isModelportfolio}
      isPtfTradingDisabled={isPtfTradingDisabled}
      isTradingAvailable={isTradingAvailable}
      canTradePortfolios={canTradePortfolios}
      custodiansInstrumentsData={custodiansInstrumentsData}
      loading={loading}
      banksMapping={banksMapping}
      bankCode={bankCode}
      customerData={customerData}
      instrumentsGroupBy={instrumentsGroupBy}
      showCell={props.showCell}
      user={auth && auth.user}
      footerText={footerText}
      withCompletelySoldAssets={withCompletelySoldAssets}
    />
  };

  const renderData = () => {
    return (
      <>
        {isDataExist && isExpandedDepotSection && (
          <>
            {instrumentsGroupBy == GROUP_ACTION_DEPOT
              ? showAssetsOverview ? (
                <>
                  <DashboardChartSection
                    title={"Wertpapiere"}
                    content={renderAssetTable()}
                    expanded={isExpanded(expandedItems, `securities.${portfolio.id}`)}
                    onExpanded={onExpandedSecuritiesChange}
                    customClasses={{
                      container: classes.tableSection,
                      headerContainer: classes.headerContainer,
                      header: classes.tableSectionHeader
                    }}
                  />
                  {!_.isEmpty(baseFondsData.components) && (
                    <DashboardChartSection
                      title={"Basisfonds"}
                      expanded={isExpanded(expandedItems, `baseFonds.${portfolio.id}`)}
                      onExpanded={onExpandedBaseFondsChange}
                      content={renderAssetTable(baseFondsData, 'baseFondsSubItems')}
                      customClasses={{
                        container: classes.tableSection,
                        headerContainer: classes.headerContainer,
                        header: classes.tableSectionHeader
                      }}
                    />
                  )}
                </>
              ) : null
              : <div className={classes.tableSection}>{renderAssetTable()}</div>
            }
          </>
        )}
        {isExpandedDepotSection && !_.isEmpty(portfolio.clearing_account) && (
          <DashboardChartSection
            title={"Verrechnungskonto"}
            expanded={isExpanded(expandedItems, `clearingAccounts.${portfolio.id}`)}
            onExpanded={onExpandedClearingAccountsChange}
            content={<ClearingAccountTable data={portfolio.clearing_account}/>}
            customClasses={{
              container: classes.tableSection,
              headerContainer: classes.headerContainer,
              header: classes.tableSectionHeader
            }}
          />
        )}
        {isExpandedDepotSection && withTransactionSaldo && renderUnrealizedProfitAndLossItem(portfolio.id)}
      </>
    );
  };

  const portfolioName = portfolio.not_connected ? portfolio.name : `${portfolio.name} (${toGermanFormat(portfolio.weight * 100, '', ' %')})`;

  const handleAddTradingOption = (instrument, tradingType, deletePaymentPlanTradingType) => {
    if (onAddTradingOption) {
      onAddTradingOption(portfolio, instrument, tradingType, deletePaymentPlanTradingType)
    }
  }

  const handleAddPortfolioTradingOption = (event, deletePaymentPlanTradingType) => {
    if (onAddPortfolioTradingOption) {
      onAddPortfolioTradingOption(portfolio, event.target.value, deletePaymentPlanTradingType)
    }
  }

  const renderVirtualDepotActions = () => {
    if(!isVirtual || portfolio.is_group || (props.isVirtualOrderEnabled && !triggerPortfolioDeleting)) return;

    return (
      <Grid container spacing={1} alignItems={"center"} style={{marginRight: 'auto'}}>
        {/* for new virtual order the order btn is available -> do not render link for old logic */}
        {!props.isVirtualOrderEnabled &&
          <Grid item>
            <Link
              to={{
                pathname: buildCurrentCustomerVirtualPortfolioManager(portfolio.id),
                state: {
                  portfolioName: portfolio.name
                }
              }}
              className={classes.link}
            >
              <Button className={classes.linkButton}>Bearbeiten</Button>
            </Link>
          </Grid>
        }
        {triggerPortfolioDeleting && (
          <Grid item>
            <Button className={classes.linkButton}
              onClick={() => {
                triggerPortfolioDeleting(portfolio.id, portfolioName)
              }}
              disableRipple={true}
            >
              Depot Löschen
            </Button>
          </Grid>
        )}
      </Grid>
    )
  };

  const renderUnrealizedProfitAndLossItem = (portfolio_id) => {
    const expanded = isExpanded(expandedItems, `transactionsSaldo.${portfolio_id}`);
    const onExpandedItemsChange = (expanded) => onExpanded(['instrumentsTab', 'transactionsSaldo', portfolio.id], expanded);
    const portfolioTransactData = _.get(unrealizedProfitAndLossData, `portfolios_transactions_data.${portfolio_id}`);

    return (
      <>
      {portfolioTransactData &&
        <div style={{marginTop: 16}}>
          <UnrealizedProfitAndLossItem
            expanded={expanded}
            onExpandedItemsChange={onExpandedItemsChange}
            portfolioTransactData={portfolioTransactData}
          />
        </div>
      }
      </>
    )
  }

  const sideNavigationContent = (
    <>
      {!portfolio.not_connected && !portfolio.is_group && (
        <DepotSummaryNavigation depot={portfolio} reportType={reportType} showColumnCallback={props.showCell}/>
      )}
    </>
  );

  const [baseFondsData, assetTableData] = React.useMemo(() => {
    const baseFonds = _.get(portfolio, 'model_portfolio_data.base_fonds');
    let baseFondsComponents = [], components = [];
    if (!_.isEmpty(baseFonds)) {
      dataSource.forEach(i => {
        if(i.is_profit_loss){
          const baseFondSoldItems = [], soldItems = [];
          (i.sub_components || []).forEach(sub_i => {
            if(baseFonds.includes(sub_i.isin)){
              baseFondSoldItems.push(sub_i);
            } else {
              soldItems.push(sub_i);
            }
          });
          if(!_.isEmpty(baseFondSoldItems)){
            baseFondsComponents.push({...i, sub_components: baseFondSoldItems});

            // keep remaining sold assets in main line if any
            if(!_.isEmpty(soldItems)){
              components.push({...i, sub_components: soldItems});
            }
          }
        } else if(baseFonds.includes(i.isin)){
          baseFondsComponents.push(i);
        } else {
          components.push(i)
        }
      });
    } else {
      components = dataSource
    }

    return [{...portfolio, components: baseFondsComponents}, {...portfolio, components: components}]
  }, [portfolio]);


  return (
    <Grid item xs={12}>
      <ChartSectionBordered
        dataId={`instrument-table-${portfolio.id}`}
        title={(
          <>
            <ChartSectionPortfolioName portfolio={portfolio} />
            {!portfolio.is_empty && !portfolio.not_connected && (
              <>
                {renderVirtualDepotActions()}
              </>
            )}
          </>
        )}
        titleControl={sideNavigationContent}
        showTitleControlFolded
        expanded={isExpandedDepotSection}
        isPortfolioSection
        skipContentPadding
        borderLeftColor={portfolio.color}
        onExpanded={handleExpandedDepotsChange}
        content={renderData()}
      />
    </Grid>
  )
}

export default connect(mapStateToProps)(withRouter(InstrumentListItem));
