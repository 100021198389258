import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';

import BaseDialog from '../../../../../../../../../../../components/BaseDialog/BaseDialog';
import { AuthResource, PortfolioBuilderResource, ModelPortfolioResource } from '../../../../../../../../../../../utils/api';
import {
  displayErrorSnackBar,
  displayWarningSnackBar
} from '../../../../../../../../../../../components/SnackbarProvider/actions';
import PrimaryButton from '../../../../../../../../../../../components/Buttons/PrimaryButton';
import { getErrorMessage } from '../../../../../../../../../../../utils/utils';
import PromptDialog from '../../../../../../../../../../../components/PromptDialog';
import { getPortfolioBuilderExcludedMsg, PORTFOLIO_BUILDER_NO_ASSETS_MSG } from '../../../../../../../../../utils';

const RISK_MAPPING = {
  "1": "RISK_PROFILE_2", // TODO: should be disabled
  "2": "RISK_PROFILE_2",
  "3": "RISK_PROFILE_3",
  "4": "RISK_PROFILE_4",
  "5": "RISK_PROFILE_5",
  "6": "RISK_PROFILE_6",
  "7": "RISK_PROFILE_7"
};

const LOSS_CAPACITY_MAPPING = {
  "1": 25, // NONE
  "2": 50, // FULL
  "3": 75, // ADDITIONAL
};

const INVESTMENT_MOTIVE_MAPPING = {
  '1': 'RETIREMENT_PROVISION',
  '2': 'GENERAL_WEALTH_CREATION',
  '3': 'PERFORMANCE_PARTICIPATION',
};

const INVESTMENT_DURATION_MAPPING = {
  '1': 'SHORT_TERM',
  '2': 'MIDDLE_TERM',
  '3': 'LONG_TERM',
  '4': 'LONG_TERM',
};

const LOADER_COMPONENT = (
  <svg
    style={{
      color:'#0092E5', position:'absolute', top:'50%', left:'50%', width: 50, height: 50,
      animation:'circular-rotate 1.4s linear infinite'
    }}
    viewBox="22 22 44 44"
  >
    <circle
      style={{stroke:'currentColor', animation:'circular-dash 1.4s ease-in-out infinite'}}
      cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"
    />
  </svg>
);

const LOADER_HTML = (
  `<html lang="de">
    <head>
      <title>Portfolio-Builder</title>
      <style>
        @keyframes circular-dash {
          0% {
            stroke-dasharray: 1px, 200px;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -15px;
          }
          100% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -125px;
          }
        }
        @keyframes circular-rotate {
          0% {
            transform-origin: 50% 50%;
          }
          100% {
            transform: rotate(360deg)
          }   
        }
      </style>
    </head>
    <body>${ReactDOMServer.renderToString(LOADER_COMPONENT)}</body>
  </html>`
);

const STATES = {
  OPEN: 'OPEN',
  FINAL: 'FINAL'
};

const PortfolioBuilder = (props) => {
  const {
    caseData,
    setCaseData,
    dataService,
    investmentAmount,
    closeHandler,
    setRecommendations,
    dispatch,
    instrumentsHandler,
  } = props;

  const [loading, setLoading] = React.useState(true);
  const [invalidISINsData, setInvalidISINsData] = React.useState(undefined);
  // state to force re-render in case "continue"
  const [, setRenderAt] = React.useState(+new Date());
  const caseWindowRef = React.useRef();

  const handleNewWindowBlocked = () => {
    closeHandler();

    console.log('POPUP BLOCKED?');
  };

  const _createCase = (withLogin, body) => {
    const portfolio = _.cloneDeep(body.portfolio);
    _.set(portfolio, 'portfolioValue', {
      "value": {
        "amount": _.sumBy(_.get(portfolio, 'holdings', []), 'amount'),
        "currencyCode": "EUR"
      }
    });
    PortfolioBuilderResource.createCase({...body, portfolio: portfolio})
      .then((data) => {
        setCaseData(data);
        setCaseWindowUrl(data.open_url);
        setLoading(false);

        if(dataService.exampleSession) {
          closeHandler();
        }
      })
      .catch((error) => {
        const data = _.get(error, 'data');

        if (withLogin && _.has(data, 'login_url')) {
          const loginUrl = data.login_url.replace('%3A8000', '%3A3000');
          const redirectUri = new URL(loginUrl).searchParams.get('redirect_uri');

          window.loginCallback = function (authParams) {
            setCaseWindowUrl();
            delete window.loginCallback;

            let requestBody = {
              ...authParams,
              code_verifier: data.code_verifier,
              redirect_uri: redirectUri
            };
            AuthResource.at('token/')
              .post(requestBody)
              .then(() => _createCase(false, body))
              .catch(handleError);
          };

          setCaseWindowUrl(loginUrl);
        } else if (_.has(data, 'invalid_isins')) {
          setInvalidISINsData({
            invalidISINs: data.invalid_isins,
            body: {
              ...body,
              portfolio: {
                ...body.portfolio ,
                holdings: _.filter(body.portfolio.holdings, a => !data.invalid_isins.includes(a.isin))
              }
            }
          });
          caseWindowRef.current.close()
        } else {
          handleError(error);
          setLoading(false);
        }
      })
  };

  const startNewCase = () => {
    setLoading(true);
    openCaseWindow();

    dataService.getPortfolioBuilderInfo().then((params) => {
      // if no esg value is set use max 100%
      const getESGPerc = (value) => _.round((value || 100) / 100, 2);

      const goals = {
        "investmentMotive": INVESTMENT_MOTIVE_MAPPING[params.investmentMotive],
        "investmentDuration": INVESTMENT_DURATION_MAPPING[params.investmentDuration],
      };

      if(!_.isNil(investmentAmount)){
        goals["investmentAmountAdjustment"] = {
          "value": {
            "amount": investmentAmount,
            "currencyCode": "EUR"
          }
        }
      }

      const body = {
        "correlationId": dataService.session_id,
        "exampleSession": !!dataService.exampleSession,
        "clientCategory": "PRIVATE_CUSTOMER",
        "displayValues": {
          "displayFirstName": params.displayFirstName,
          "displayLastName": params.displayLastName,
        },
        "riskProfile": {
          "riskStrategy": RISK_MAPPING[params.sri],
          "lossBearingCapacity": LOSS_CAPACITY_MAPPING[params.lossCapacity]
        },
        "knowledgeAndExperience": {
          "knowledgeInAssetGroups": params.knowledgeInAssetGroups
        },
        "sustainability": {
          "generalSustainabilityProportionMin": 0,
          "generalSustainabilityProportionMax": 1,
          "useGeneralSustainabilityProportion": false,
          "ecologicallySustainableInvestmentsProportionMin": 0,
          "ecologicallySustainableInvestmentsProportionMax": getESGPerc(params.esg.oko),
          "sustainableInvestmentsProportionMin": 0,
          "sustainableInvestmentsProportionMax": getESGPerc(params.esg.esg),
          "principalAdverseImpactsProportionMin": 0,
          "principalAdverseImpactsProportionMax": getESGPerc(params.esg.pai),
        },
        "goals": goals,
        "portfolio": {
          "settlementAccount": {
            "value": {
              "amount": params.settlementAccount || 0,
              "currencyCode": "EUR"
            }
          },
          "holdings": (params.holdings || []).filter(i => i.quantity > 0).map(i => ({isin: i.isin, quantity: i.quantity, amount: i.market_value})),
        }
      };

      _createCase(true, body)
    }).catch(handleError);
  };

  const setCaseWindowUrl = (url) => {
    if(caseWindowRef.current){
      if (url){
        caseWindowRef.current.window.location.assign(url);
      } else {
        caseWindowRef.current.document.close();
        caseWindowRef.current.document.write(LOADER_HTML);
        caseWindowRef.current.document.close();
      }
    }
  };

  const openCaseWindow = (url) => {
    // Note: in case target exists - it will be focused instead open new window
    const w = window.open(undefined, `Portfolio Builder Case For ${dataService.session_id}`,
      `width=${window.screen.availWidth},height=${window.screen.availHeight}`);
    if(w) {
      caseWindowRef.current = w;

      try {
        setCaseWindowUrl(url)
      } catch (e) {
        // in case we can't access just opened window it's existing PB window
        if (!url) {
          w.close();
          openCaseWindow();
        } else {
          w.focus()
        }
      }
    } else {
      handleNewWindowBlocked()
    }
  };

  const deleteCase = () => {
    setCaseData(undefined);
    closeHandler();
  };

  const getCaseState = () => {
    setLoading(true);
    PortfolioBuilderResource.getCaseStatus(caseId)
      .then((res) => {
        setCaseData({...caseData, ...res});  // update caseData with latest state
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  };

  const getCaseResult = () => {
    setLoading(true);
    PortfolioBuilderResource.getCaseResult(caseId)
      .then(async (res) => {
        if (!_.isEmpty(res)){
          setCaseData({...caseData, state: STATES.FINAL});

          const isinsData = {};
          res.forEach(a => isinsData[a.isin] = {
            amount: _.get(a, 'amount.amount', 0),
            // quantity: a.quantity, // TODO: should we use qty for sell?
            recommendationType: a.type,
            fromPortfolioBuilder: true
          });

          let excluded = [];
          let items = await ModelPortfolioResource.infoAssets(Object.keys(isinsData));
          if (instrumentsHandler) {
            [items, excluded] = await instrumentsHandler.getCustodianAndFilterInstruments(items, true);
          }
          if (items.length) {
            items.forEach(i => Object.assign(i, {...isinsData[i.isin]}));
            setRecommendations(items);
            if (excluded.length){
              dispatch(displayWarningSnackBar(getPortfolioBuilderExcludedMsg(excluded)))
            }
          } else {
            dispatch(displayWarningSnackBar(PORTFOLIO_BUILDER_NO_ASSETS_MSG))
          }
        } else {
          dispatch(displayErrorSnackBar(
            "Bitte Portfolio-Builder-Sitzung zunächst abschließen, indem Sie auf “Anlagevorschlag übernehmen” " +
            "klicken. Hinweis: Eine bereits gestartet Sitzung können Sie erneut öffnen, indem Sie auf den Knopf " +
            "“Portfolio-Builder” klicken."));
        }
      })
      .catch(handleError)
      .finally(() => setLoading(false))
  };

  const continueSession = () => {
    // Note: as caseWindow is ref update url will not trigger rerender so need to update state
    openCaseWindow(caseData.open_url);
    setRenderAt(+new Date());
  };

  const caseId = caseData.caseId;
  const caseMappingData = {
    [STATES.OPEN]: {
      text: "Möchten Sie die letzte offene Sitzung fortsetzen oder eine neue beginnen?",
      actions: <Grid container spacing={2} justify={'flex-end'}>
        <Grid item>
          <PrimaryButton
            text={"Fortsetzen"}
            variant={"outlined"}
            onButtonClick={continueSession}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            text={"Neu starten"}
            onButtonClick={startNewCase}
          />
        </Grid>
      </Grid>,
      maxWidth: 'sm',
    },
    [STATES.FINAL]: {
      text: "Sie haben den Portfolio-Builder gestartet. Nachdem Sie im Portfolio-Builder-Reiter erfolgreich einen " +
        "Anlagevorschlag generiert und auf “Anlagevorschlag übernehmen” geklickt haben, können Sie hier Ihre Beratung " +
        "fortsetzen.",
      actions: <Grid container spacing={2} justify={'flex-end'}>
        <Grid item>
          <PrimaryButton
            text={"Bestehende Sitzung löschen"}
            variant={"outlined"}
            onButtonClick={deleteCase}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            text={"Bestehende Sitzung fortsetzen"}
            onButtonClick={continueSession}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            text={"Anlagevorschlag in DIVA übernehmen"}
            onButtonClick={getCaseResult}
          />
        </Grid>
      </Grid>,
      maxWidth: 'md',
    }
  }[caseWindowRef.current ? STATES.FINAL : _.get(caseData, 'state')] || {};

  const handleError = (error) => {
    dispatch(displayErrorSnackBar(getErrorMessage(error) || 'Fehler beim Datenübertrag. Bitte versuchen Sie es später erneut'));
    close();
  };

  const close = () => {
    if(caseWindowRef.current){
      caseWindowRef.current.close();
    }
    closeHandler()
  };

  React.useEffect(() => {
    if(!caseId) {
      startNewCase();
    } else {
      getCaseState()
    }
  }, []);

  const depotName = dataService.exampleSession ? 'Musterdepot' : 'Depot';

  return (
    <>
      <BaseDialog
        title={"Portfolio-Builder"}
        open={!dataService.exampleSession}
        maxWidth={caseMappingData.maxWidth}
        modalLoading={loading}
        handleClose={closeHandler}
        actions={caseMappingData.actions}
      >
        {caseMappingData.text}
      </BaseDialog>
      <PromptDialog
        title={"Portfolio-Builder"}
        isOpen={!_.isEmpty(invalidISINsData)}
        cancelBtnLabel='Abbrechen'
        okBtnLabel='Fortfahren'
        handleNoBtn={close}
        handleYesBtn={() => {
          openCaseWindow();
          _createCase(false, invalidISINsData.body)
        }}
        message={
          <>
            <p>
            Das {depotName} enthält folgende Produkte {_.join(_.get(invalidISINsData, 'invalidISINs', []), ", ")} welche vom
            Portfolio-Builder nicht verarbeitet werden können.
            </p>
            <p>Möchten Sie das {depotName} ohne diese Produkte übergeben?</p>
          </>
        }
      />
    </>
  )
};

export default connect()(PortfolioBuilder);