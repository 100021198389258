import React from 'react';
import queryString from 'query-string';
import {withRouter} from "react-router";
import {connect} from 'react-redux';
import {
  TOKEN_KEY,
  setInStorage, NEW_ORIGIN_KEY,
} from "../../../utils/storage";
import {SharedSettingsResource} from "../../../utils/api";

import CircularProgress from '@material-ui/core/CircularProgress';

import {login, logout} from "./actions";
import ErrorModal from '../../../components/ErrorModal';
import {PERMISSION_ERROR_MODAL_MESSAGE, DEFAULT_ORIGIN} from "../../../utils/constants";
import {AuthResource} from "../../../utils/api";
import {saveCustomerIdByPathName, saveCustomerIdByUserName} from "../../CustomerDashboard/utils";
import {brokerLogout, customerLogout} from "./utils";
import { setSharedSettings } from '../../../components/SharedSettingsProvider/actions';
import {getSharedSettingsSelector} from "../../../components/DashboardDataProvider/DashboardDataProvider";
import {getAuthSelector} from "../../../utils/redaxSelectors";

const mapStateToProps = (state) => ({
  auth: getAuthSelector(state),
  sharedSettings: getSharedSettingsSelector(state),
});

class AuthenticationProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: undefined
    };
    this.handleBrokerAuthFlow = this.handleBrokerAuthFlow.bind(this);
    this.handleErrorModalClose = this.handleErrorModalClose.bind(this);
  }

  componentWillMount() {
    (async () => {
      await this.handleBrokerAuthFlow();
      this.props.handleInvestmentDynamicPath()
    })()
  }

  async retriveSharedSettings() {
    try {
      const sharedSettings = await SharedSettingsResource.at('data/').get();
      this.props.dispatch(setSharedSettings(sharedSettings || {}));
    } catch {
      this.props.dispatch(setSharedSettings({}));
    }
  }

  async handleBrokerAuthFlow() {
    const queryParams = queryString.parse(this.props.location.search);

    const broker_id = queryParams['broker_id'];
    const customer_id = queryParams['customer_id']
    const code = queryParams['code'];
    const origin = queryParams['origin'] || DEFAULT_ORIGIN;

    // in case it's popup window for OpenID Auth
    try {
      if (code && window.opener && window.opener.hasOwnProperty('loginCallback')) {
        window.opener.loginCallback(queryParams);
      }
    } catch (e) {
      console.error(e)
    }

    if ((broker_id || customer_id) && code) {
      try {
        this.setState({loading: true});

        let requestBody = {code}
        if (customer_id ) {
          requestBody.customer_id = customer_id
        }
        if (broker_id ) {
          requestBody.broker_id = broker_id
        }

        const response = await AuthResource.at('token/').post(requestBody);
        const user = {...response.user};

        if (broker_id) {
          user.broker_id = broker_id
          saveCustomerIdByPathName(this.props.location.pathname);
        } else if (customer_id) {
          user.customer_id = customer_id
          user.broker_id = customer_id
          saveCustomerIdByUserName(response.user.username);
        }

        setInStorage(NEW_ORIGIN_KEY, origin);
        setInStorage(TOKEN_KEY, response.token);

        this.props.dispatch(login(user));

        this.setState({loading: false, error: undefined});
        await this.retriveSharedSettings()
        window.history.replaceState(null, document.title, window.location.origin + window.location.pathname);
      }catch (error) {
        this.props.dispatch(logout());
        this.setState({loading: false, error});
      }
    }

  };

  handleErrorModalClose() {
    if (this.props.auth.user && this.props.auth.user.is_customer) {
      return customerLogout();
    }

    if (this.props.auth.user && this.props.auth.user.is_broker) {
      return brokerLogout();
    }
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <div style={{
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <CircularProgress/>
          </div>
        ) : (
          <>
            {this.props.children}
          </>
        )}
        {(this.state.error || this.props.auth.error) && (
          <ErrorModal message={PERMISSION_ERROR_MODAL_MESSAGE} handleClose={this.handleErrorModalClose.bind(this)}/>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(AuthenticationProvider));